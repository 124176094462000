import $ from 'jquery';
import { isFunction } from './is';

$.getMultiScripts = function (arr, path) {
  const _arr = $.map(arr, scr => $.getScript((path || '') + scr));

  _arr.push(
    $.Deferred(deferred => {
      $(deferred.resolve);
    }),
  );

  /* eslint-disable prefer-spread */
  return $.when.apply($, _arr);
};

export const lazyLoadJavascripts = (urls, callback) => {
  let isTriggered = null;

  $(window).one('scroll mousemove tap click', (e) => {
    if (isTriggered) return;

    isTriggered = true;

    $.getMultiScripts(urls).done(() => {
      if (isFunction(callback)) {
        callback();
      }
    });
  });
};

export const lazyLoadBackgrounds = () => {
  let lazyloadImages;

  if ('IntersectionObserver' in window) {
    lazyloadImages = document.querySelectorAll('.lazy-background');
    const imageObserver = new IntersectionObserver(((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target;

          image.classList.remove('lazy-background');
          imageObserver.unobserve(image);
        }
      });
    }));

    lazyloadImages.forEach((image) => {
      imageObserver.observe(image);
    });
  } else {
    let lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll('.lazy-background');

    // eslint-disable-next-line no-inner-declarations
    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(() => {
        const scrollTop = window.pageYOffset;
        lazyloadImages.forEach((img) => {
          if (img.offsetTop < (window.innerHeight + scrollTop)) {
            // eslint-disable-next-line no-param-reassign
            img.src = img.dataset.src;
            img.classList.remove('lazy-background');
          }
        });
        if (lazyloadImages.length === 0) {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        }
      }, 20);
    }

    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }
};
