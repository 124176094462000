import $ from 'jquery';
import 'slick-carousel/slick/slick';
import '../../../source/scripts/scrollUp.js';
import { lazyLoadJavascripts } from '../../../source/scripts/helpers/lazy';
import { BREAKPOINTS } from '@/scripts/helpers/constants';
import { accordion } from '../../../source/modules/containers/accordion/index';

const { TABLET_XL, DESKTOP_MD } = BREAKPOINTS;
const VISIBLE = 'is-visible';
const OPEN = 'is-open';
const NO_SCROLL = 'no-scroll';
const NOT_INITIALIZED = '.slick-initialized';

const sliderConfig = {
  variableWidth: true,
  slidesToScroll: 1,
  swipeToSlide: true,
  pauseOnHover: false,
  arrows: false,
  fade: false,
  autoplay: true,
  infinite: true,
  dots: false,
  autoplaySpeed: 0,
  speed: 6000,
  cssEase: 'linear',
  useTransform: false,
};

const initBurger = (selector, menu, parentItem) => {
  selector.click(() => {
    selector.toggleClass(OPEN);
    menu.toggleClass(OPEN);
    $('body').toggleClass(NO_SCROLL);
  });

  parentItem.click(() => {
    parentItem.toggleClass(OPEN);
  });
}

const slickCarouselConfig = {
  slidesToShow: 1,
  pauseOnHover: false,
};

const initSlickCarousel = (selector, config = slickCarouselConfig) => {
  if ($(selector).length) {
    $(selector)
      .on("init", () => {
        $(selector).addClass(VISIBLE);
      })
      .not(NOT_INITIALIZED)
      .slick(config);
  }
};

const initTrustSealsCarouselSm = (selector) => {
  if ($(selector).length) {
    const sliderConfigSM = {
      responsive: [
        {
          breakpoint: 99999999,
          settings: 'unslick',
        },
        {
          breakpoint: TABLET_XL,
          settings: {
            ...sliderConfig,
          },
        },
      ],
    };

    initSlickCarousel(selector, sliderConfigSM);

    $(window).on('resize', () => {
      initSlickCarousel(selector, sliderConfigSM);
    });
  }
};

const initReviewsSlider = () => {
  const otherFormsSlider = $('.js-reviews-slider');
  const prevArrow = `<button type="button" class="slider-arrow slider-arrow--prev"></button>`;
  const nextArrow = `<button type="button" class="slider-arrow slider-arrow--next"></button>`;

  if (otherFormsSlider.length) {
    otherFormsSlider.not('.slick-initialized').slick({
      infinite: true,
      focusOnSelect: false,
      pauseOnFocus: false,
      arrows: true,
      prevArrow,
      nextArrow,
      cssEase: 'linear',
      variableWidth: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: DESKTOP_MD,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
          },
        },
      ],
    });
  }
};

const initSlider = (selector, arrowPrev, arrowNext) => {
  if (selector) {
    selector.not('.slick-initialized').slick({
      responsive: [
        {
          breakpoint: 99999,
          settings: 'unslick',
        },
        {
          breakpoint: DESKTOP_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: arrowPrev,
            nextArrow: arrowNext,
          }
        },
        {
          breakpoint: TABLET_XL,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: arrowPrev,
            nextArrow: arrowNext,
          }
        }
      ]
    });
  }
};

const showDetails = () => {
  $('.js-section-details').toggleClass('is-open');
}

const showAdvantage = () => {
  $('.js-section-advantage').toggleClass('is-open');
}

const initRaiting = (selector) => {
  const ratingStars = selector.find('.raiting__star');

  const handleHoverStar = function () {
    const index = $(this).index();
    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).addClass('is-active');
    }
  };

  const handleBlurStar = function () {
    ratingStars.removeClass('is-active');
  };

  const handleClickStar = function () {
    const index = $(this).index();
    ratingStars.removeClass('is-half-filled is-filled').addClass('star--empty');

    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).removeClass('star--empty').addClass('is-filled');
    }

    localStorage.setItem('rating-star', index + 1);
  };

  ratingStars.hover(handleHoverStar, handleBlurStar);
  ratingStars.click(handleClickStar);
};

$(document).ready(() => {
  const burger = $('.js-burger');
  const mobileMenu = $('.menu');
  const menuItem = $('.menu__item--has-child');

  initRaiting($('.raiting__stars'));
  initTrustSealsCarouselSm($('.js-badges-slider'));
  initReviewsSlider();
  accordion();
  initBurger(burger, mobileMenu, menuItem);
  initSlider($('.js-related-slider'), $('.slider-arrow--prev-related'), $('.slider-arrow--next-related'));
  $('.js-details-show, .js-details-hide').on('click', showDetails);
  $('.js-advantage-show, .js-advantage-hide').on('click', showAdvantage);

  $(window).on( 'resize', function() {
    initSlider($('.js-related-slider'), $('.slider-arrow--prev-related'), $('.slider-arrow--next-related'));
    initReviewsSlider();
  });
});

window.$ = $;
window.lazyLoadJavascripts = lazyLoadJavascripts;
